// ----------------- Allu Arjun Images ----------------
import AA1Daddy from "./../Media/AlluArjun/1 2023.jpg";
import AA2Gangothri from "./../Media/AlluArjun/2 Gangothri.jpg";
import AA3Arya from "./../Media/AlluArjun/3 Arya.jpg";
import AA4Bunny from "./../Media/AlluArjun/4 Bunny 2.jpg";
import AA5Happy from "./../Media/AlluArjun/5 Happy.jpg";
import AA6SDZB from "./../Media/AlluArjun/6 SDZB.jpg";
import AA7DM from "./../Media/AlluArjun/7 DM.jpg";
import AA8Parugu from "./../Media/AlluArjun/8 Parugu.jpg";
import AA9Arya from "./../Media/AlluArjun/9 Arya 2.jpg";
import AA10Varudu from "./../Media/AlluArjun/10 Varudu.jpg";
import AA11Vedam from "./../Media/AlluArjun/11 Vedam.jpg";
import AA12Badhrinath from "./../Media/AlluArjun/12 badhrinath.jpg";
import AA13Zulayi from "./../Media/AlluArjun/13 Zulayi.jpg";
import AA14Iddarammailatho from "./../Media/AlluArjun/14 Iddarammailatho.jpg";
import AA15Resugurram from "./../Media/AlluArjun/15 resugurram.jpg";
import AA16Yevadu from "./../Media/AlluArjun/16 Yevadu.jpg";
import AA17SOSatyaMurthi from "./../Media/AlluArjun/17 SO Satyamurthi.jpg";
import AA18Rudramadevi from "./../Media/AlluArjun/18 Rudrama Devi.jpg";
import AA19Sarainodu from "./../Media/AlluArjun/19 Sarainodu 2.jpg";
import AA20Dj from "./../Media/AlluArjun/20 dj.jpg";
import AA21Naperusurya from "./../Media/AlluArjun/21 Na peru surya.jpg";
import AA22Alavaikuntapuramlo from "./../Media/AlluArjun/22 Alavaikuntapuramlo.jpg";
import AA23Pushpa from "./../Media/AlluArjun/23 Pushpa.webp";
// ----------------- Allu Arjun Images END   ----------------

export const AlluArjun = [
  {
    id: 1,
    image: AA1Daddy,
    name: "Daddy",
    year: "2001",
  },

  {
    id: 2,
    image: AA2Gangothri,
    name: "Gangothri",
    year: "2003",
  },

  {
    id: 3,
    image: AA3Arya,
    name: "Arya",
    year: "2004",
  },

  {
    id: 4,
    image: AA4Bunny,
    name: "Bunny",
    year: "2005",
  },
  {
    id: 5,
    image: AA5Happy,
    name: "Happy",
    year: "2006",
  },
  {
    id: 6,
    image: AA6SDZB,
    name: "SDZB",
    year: "2007",
  },
  {
    id: 7,
    image: AA7DM,
    name: "Deshamuduru",
    year: "2007",
  },
  {
    id: 8,
    image: AA8Parugu,
    name: "Parugu",
    year: "2008",
  },
  {
    id: 9,
    image: AA9Arya,
    name: "Arya",
    year: "2009",
  },
  {
    id: 10,
    image: AA10Varudu,
    name: "Varudu",
    year: "2010",
  },
  {
    id: 11,
    image: AA11Vedam,
    name: "Vedam",
    year: "2010",
  },
  {
    id: 12,
    image: AA12Badhrinath,
    name: "Badhrinath",
    year: "2011",
  },
  {
    id: 13,
    image: AA13Zulayi,
    name: "Zulayi",
    year: "2012",
  },
  {
    id: 14,
    image: AA14Iddarammailatho,
    name: "Iddarammailatho",
    year: "2013",
  },
  {
    id: 15,
    image: AA15Resugurram,
    name: "Resugurram",
    year: "2014",
  },
  {
    id: 16,
    image: AA16Yevadu,
    name: "Yevadu",
    year: "2014",
  },
  {
    id: 17,
    image: AA17SOSatyaMurthi,
    name: "S/OSatyaMurthi",
    year: "2015",
  },
  {
    id: 18,
    image: AA18Rudramadevi,
    name: "S/OSatyaMurthi",
    year: "2015",
  },
  {
    id: 19,
    image: AA19Sarainodu,
    name: "Sarainodu",
    year: "2016",
  },
  {
    id: 20,
    image: AA20Dj,
    name: "DJ",
    year: "2017",
  },
  {
    id: 21,
    image: AA21Naperusurya,
    name: "Na Peru Surya",
    year: "2018",
  },
  {
    id: 22,
    image: AA22Alavaikuntapuramlo,
    name: "Alavaikuntapuramlo",
    year: "2020",
  },
  {
    id: 23,
    image: AA23Pushpa,
    name: "Pushpa",
    year: "2021",
  },
];
