import React from "react";
import "./Card.scss";
import { Tilt } from "react-tilt";
import { useState } from "react";

function Card(props, image, name, year) {
  const [hovered, setHovered] = useState(false);

  const handleHover = () => {
    setHovered(true);
  };

  const handleLeave = () => {
    setHovered(false);
  };

  const defaultOptions = {
    reverse: false, // reverse the tilt direction
    max: 35, // max tilt rotation (degrees)
    perspective: 1000, // Transform perspective, the lower the more extreme the tilt gets.
    scale: 1.1, // 2 = 200%, 1.5 = 150%, etc..
    speed: 1000, // Speed of the enter/exit transition
    transition: true, // Set a transition on enter/exit.
    axis: null, // What axis should be disabled. Can be X or Y.
    reset: true, // If the tilt effect has to be reset on exit.
    easing: "cubic-bezier(.03,.98,.52,.99)", // Easing on enter/exit.
  };
  return (
    <div
      className="card-part"
      onMouseEnter={handleHover}
      onMouseLeave={handleLeave}
    >
      <Tilt options={defaultOptions}>
        <div>
          <img src={props.image} alt="" className="card-image" />
          {hovered && (
            <div className="overlay">
              <div className="text">
                <p className="text-info1">
                  <span className="font-bold">Director:</span> Chatrapathi
                </p>
                <p className="text-info1">
                  <span className="font-bold">Rating:</span> 4.5/5
                </p>
              </div>
            </div>
          )}
        </div>
      </Tilt>
      <div className="card-content">
        <h3 className="card-heading">{props.name}</h3>
        <p className="card-paragraph">{props.year}</p>
      </div>
    </div>
  );
}

export default Card;
