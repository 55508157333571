import "./App.css";
import "./App.scss";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./Pages/HomePage";
import TestingPage from "./Pages/TestingPage";
import Politics from "./Pages/Politics";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />}>
          <Route index element={<HomePage />} />
        </Route>
        <Route path="/Testing" element={<TestingPage />} />
        <Route path="/Politics" element={<Politics />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
