import React, { useEffect, useState } from "react";
import Navbar from "../Components/Navbar/Navbar";
import modi from "./ww.jpg";

function Politics() {
  const [data, setData] = useState([]);

  useEffect(() => {
    // Fetch data from the Express.js backend
    fetchData();
  });

  const fetchData = async () => {
    try {
      const response = await fetch("http://localhost:3000");
      const jsonData = await response.json();

      // Update the state with the fetched data
      setData(jsonData);
      console.log(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  return (
    <div>
      <Navbar />

      <h1>Above</h1>
      <ul className="flex">
        {data.map((item) => (
          <li>{item.Name}</li>

          // Replace 'id' and 'name' with the actual properties of your data
        ))}
      </ul>
      <ul className="flex flex-wrap">
        {data.map((item) => (
          <li className="w-full">{item.Description1}</li>

          // Replace 'id' and 'name' with the actual properties of your data
        ))}
      </ul>
      <ul className="flex">
        {data.map((item) => (
          <li>{item.Description2}</li>

          // Replace 'id' and 'name' with the actual properties of your data
        ))}
      </ul>
      <h2>Bellow</h2>
      <div className="mt-[100px] flex flex-col justify-center place-items-center">
        <img src={modi} alt="" />
      </div>
    </div>
  );
}

export default Politics;
