// import React, { useState } from "react";
// import "./Test.css";
// import { TestData } from "./TestData";
// import "react-responsive-carousel/lib/styles/carousel.min.css";
// import { Carousel } from "react-responsive-carousel";

// function Test() {
//   const [desc, setDesc] = useState(TestData[0].desc);

//   const handleSlideChange = (index, direction) => {
//     let newIndex = index;
//     if (direction === "prev" && index === 0) {
//       newIndex = TestData.length - 1;
//     } else if (direction === "next" && index === TestData.length - 1) {
//       newIndex = 0;
//     }
//     setDesc(TestData[newIndex].desc);
//   };

//   return (
//     <div className="test-div">
//       <div className="content-div">
//         <p className="legend">{desc}</p>
//       </div>
//       <div className="slider-div">
//         <Carousel
//           showThumbs={false}
//           infiniteLoop={true}
//           onChange={handleSlideChange}
//         >
//           {TestData.map((product) => (
//             <div key={product.id}>
//               <img src={product.image} alt="" />
//             </div>
//           ))}
//         </Carousel>
//       </div>
//     </div>
//   );
// }

// export default Test;

import React, { useState } from "react";
import AwesomeSlider from "react-awesome-slider";
import "react-awesome-slider/dist/styles.css";
import "./Test.css";
import { TestData } from "./TestData";
import "react-awesome-slider/dist/custom-animations/cube-animation.css";
import Navbar from "../Navbar/Navbar";

function Test() {
  const [desc, setDesc] = useState(TestData[0].desc);

  const handleSlideChange = ({ nextIndex, prevIndex }) => {
    const newIndex = typeof nextIndex !== "undefined" ? nextIndex : prevIndex;
    setDesc(TestData[newIndex].desc);
  };

  return (
    <div>
      <div className="test-div">
        <div className="content-div">
          <p className="legend">{desc}</p>
        </div>
        <div className="slider-div">
          <AwesomeSlider
            animation="cubeAnimation"
            onTransitionRequest={handleSlideChange}
          >
            {TestData.map((product, index) => (
              <div key={product.id}>
                <img src={product.image} alt="" />
              </div>
            ))}
          </AwesomeSlider>
        </div>
      </div>
    </div>
  );
}

export default Test;
