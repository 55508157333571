import React from "react";
import { AlluArjun } from "../MainData/MoviesData/MoviesData";
import Card from "../Card/Card";
import "./MoviesDisplay.scss";

function MoviesDisplay() {
  return (
    // <div className="movies-display-part">
    //   {AlluArjun.map((data) => (
    //     <Card image={data.image} name={data.name} year={data.year} />
    //   ))}
    // </div>
    <div className="movies-display-part">
      {AlluArjun.sort((a, b) => b.id - a.id).map((data) => (
        <Card image={data.image} name={data.name} year={data.year} />
      ))}
    </div>
  );
}

export default MoviesDisplay;
