import img1 from "./one.jpg";
import img2 from "./two.jpg";
import img3 from "./three.jpeg";

export const TestData = [
  {
    id: 1,
    image: img3,
    desc: "The Earth's atmosphere is a thin layer of gases that surrounds our planet. It is composed of nitrogen, oxygen, carbon dioxide, and other trace gases. This layer protects us from harmful radiation and regulates the temperature of the planet. However, human activities such as burning fossil fuels and deforestation have led to an increase in the concentration of greenhouse gases, causing global warming and climate change.",
  },
  {
    id: 2,
    image: img2,
    desc: "Artificial intelligence (AI) is a field of computer science that aims to create intelligent machines that can perform tasks that typically require human intelligence, such as recognizing speech, understanding natural language, and making decisions. AI is rapidly advancing and is already being used in many applications, including self-driving cars, medical diagnosis, and finance. However, there are concerns about the ethical implications of AI and its potential impact on jobs and society.",
  },
  {
    id: 3,
    image: img3,
    desc: "The human brain is the most complex organ in the body, with over 100 billion neurons that communicate through electrical and chemical signals. It is responsible for our thoughts, emotions, and behaviors, and it allows us to perceive the world around us. However, the brain is also vulnerable to damage and disease, such as Alzheimer's and Parkinson's. Researchers are working to better understand the brain and develop treatments for these conditions.",
  },
  {
    id: 4,
    image: img2,
    desc: "The Earth's atmosphere is a thin layer of gases that surrounds our planet. It is composed of nitrogen, oxygen, carbon dioxide, and other trace gases. This layer protects us from harmful radiation and regulates the temperature of the planet. However, human activities such as burning fossil fuels and deforestation have led to an increase in the concentration of greenhouse gases, causing global warming and climate change.",
  },
  {
    id: 5,
    image: img3,
    desc: "Artificial intelligence (AI) is a field of computer science that aims to create intelligent machines that can perform tasks that typically require human intelligence, such as recognizing speech, understanding natural language, and making decisions. AI is rapidly advancing and is already being used in many applications, including self-driving cars, medical diagnosis, and finance. However, there are concerns about the ethical implications of AI and its potential impact on jobs and society.",
  },
  {
    id: 6,
    image: img2,
    desc: "The human brain is the most complex organ in the body, with over 100 billion neurons that communicate through electrical and chemical signals. It is responsible for our thoughts, emotions, and behaviors, and it allows us to perceive the world around us. However, the brain is also vulnerable to damage and disease, such as Alzheimer's and Parkinson's. Researchers are working to better understand the brain and develop treatments for these conditions.",
  },
];
