import React from "react";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import "./Footer.css";
import logo from "./../MainData/Logo/Sitram-white1.png";

import { BsFacebook, BsInstagram, BsTwitter } from "react-icons/bs";

const Footer = () => {
  return (
    <div
      className="px-4 py-8 footer-part1"
      //   style={{ backgroundColor: "#006D77" }}
    >
      <footer className="p-8 font-[Philosopher]">
        <section>
          <div className="sm:flex flex-row">
            <div className="basis-1/4 m-4 space-y-6">
              <h1 className="text-2xl my-2 text-white footer-heading">
                <img src={logo} alt="" />
              </h1>
              <div className="text-white font-light text-lg">
                <p>
                  Dive into a world of cinematic wonders with Sitram, your go-to
                  source for all things movies
                </p>
              </div>
              <div className="footer-icons-section">
                <BsFacebook className="footer-icon" />
                <BsInstagram className="footer-icon" />
                <BsTwitter className="footer-icon" />
              </div>
            </div>

            <div className="basis-1/4 m-4 space-y-6 ">
              <h1 className="text-2xl my-2 text-white footer-heading">
                Languages
              </h1>
              <div className="text-white font-light text-lg ">
                <p>Telugu</p>

                <p>Tamil</p>
                <p>Kannada</p>
                <p>Malayalam</p>
                <p>Hindi</p>
              </div>
            </div>
            <div className="basis-1/4 m-4 space-y-6">
              <h1 className="text-2xl my-2 text-white footer-heading">
                QUICK LINKS
              </h1>

              <div className="text-white font-light text-lg ">
                <p>TV</p>

                <p>Photos</p>
                <p>Videos</p>
                <p>Movies</p>
              </div>
            </div>
            <div className="basis-1/4 m-4 space-y-6">
              <h1 className="text-2xl my-2 text-white footer-heading">
                GET THE LATEST INFO
              </h1>
              <div className="text-white font-light text-lg">
                <p>
                  Stay up-to-date. Subscribe to our website for the latest
                  updates.
                </p>
                <br />
                <div className="flex flex-row bg-white py-1 rounded-md text-black">
                  <input
                    type="text"
                    placeholder="Enter Your Email"
                    className="p-2 border-0 w-full focus:outline-none"
                  />
                  <button className="mr-3" style={{ color: "#006D77" }}>
                    <FontAwesomeIcon icon={faPaperPlane} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pt-8 py-2 text-white font-light text-xl">
          <p className="text-center">
            Developed by{" "}
            <a
              href="https://www.linkedin.com/in/kranthi-kumar-mamidala/"
              rel="noreferrer"
              target="_blank"
              className="text-[white]"
            >
              <b>
                <u>Kranthi Kumar M</u>
              </b>
            </a>
          </p>
        </section>
      </footer>
    </div>
  );
};

export default Footer;
