import React, { useEffect, useState } from "react";
import * as Realm from "realm-web";

function Backend() {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const REALM_APP_ID = "application-ak1-ofcip";
    const app = new Realm.App({ id: REALM_APP_ID });

    async function fetchProducts() {
      const credentials = Realm.Credentials.anonymous();
      try {
        const user = await app.logIn(credentials);
        const allProducts = await user.functions.getFunctionAK1();
        setProducts(allProducts);
      } catch (error) {
        console.error(error);
      }
    }

    fetchProducts();
  }, []);

  return (
    <div>
      {products &&
        products.map((product) => {
          return (
            <div key={product._id}>
              <p>{product.name}</p>
            </div>
          );
        })}
    </div>
  );
}

export default Backend;
