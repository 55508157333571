import React from "react";
import Navbar from "../Components/Navbar/Navbar";
import Footer from "../Components/Footer/Footer";
import MoviesDisplay from "../Components/MoviesDisplay/MoviesDisplay";
import Backend from "../Components/Backend/Backend";

function HomePage() {
  return (
    <div>
      <Navbar />
      <Backend />
      <MoviesDisplay />
      <Footer />
    </div>
  );
}

export default HomePage;
