import React from "react";
import test from "./test.gif";
import "./Page.css";

import Lottie from "react-lottie";
import animationData from "./../Components/Lotties/one.json";
import animationData2 from "./../Components/Lotties/two.json";
import unique from "./../Components/Lotties/Unique.json";

import img1 from "./../Components/Lotties/three.gif";
import Test from "../Components/Test/Test";
import Navbar from "../Components/Navbar/Navbar";

function TestingPage() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "none",
    },
    className: "lottie-css-kk",
  };

  const defaultOptions1 = {
    loop: true,
    autoplay: true,
    animationData: animationData2,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
    className: "lottie-css-kk",
  };

  const defaultOptions2 = {
    loop: true,
    autoplay: true,
    animationData: unique,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div>
      <Navbar />
      <Test />
      TestingPage
      {/* <img src={test} alt="" /> */}
      {/* <Lottie options={defaultOptions} />
      <Lottie options={defaultOptions1} /> */}
      {/* <Lottie
        options={defaultOptions2}
        // width={"200px"}
        className="my-lottie-animation"
      />
      <img src={img1} alt="nothing" /> */}
    </div>
  );
}

export default TestingPage;
